import * as Yup from "yup";

const signInScheme = Yup.object().shape({
  email: Yup.string()
    .email("Bitte geben Sie eine gültige Email ein.")
    .required("Bitte geben Sie eine Email an."),
  password: Yup.string().required("Bitte geben Sie ein Passwort ein."),
});

export default signInScheme;
