import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import Link from "../Link";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    minHeight: "20vh",
    color: theme.palette.primary.light,
  },
  gridBox: {
    display: "flex",
    justifyContent: "center",
    textAlign: "start",
  },
  gridElement: {
    padding: "2rem",
  },
  title: {
    marginBottom: "0.5rem",
  },
  element: {
    color: "#9399a2",
    textDecoration: "none",
    "&:hover": {
      color: "#fff",
    },
  },
  elements: {
    color: "#9399a2",
    textDecoration: "none",
  },
  list: {
    alignItems: "center",
  },
  logoPadding: {
    padding: "1rem",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridBox}>
        <Grid
          item
          xs={12}
          md={12}
          align="center"
          className={classes.logoPadding}
        >
          <Link href="/">
            <Logo />
          </Link>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridElement}>
          <Typography variant="h6" className={classes.title}>
            M3E Group
          </Typography>

          <Typography variant="body1" className={classes.elements}>
            +49 (0) 30 403672121
          </Typography>
          <Typography
            variant="body1"
            component={Link}
            href="https://m3e-gmbh.com/"
            external
            className={classes.element}
            gutterBottom
          >
            Unternehmensseite
          </Typography>
          <div>
            <Typography
              variant="body1"
              className={classes.element}
              component={Link}
              href="/impressum"
            >
              Impressum
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} md={4} className={classes.gridElement}>
          <Typography variant="h6" className={classes.title}>
            Adresse
          </Typography>

          <Typography variant="body1" className={classes.elements}>
            Torstraße 23
          </Typography>
          <Typography variant="body1" className={classes.elements}>
            10119 Berlin
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} className={classes.gridElement}>
          <Typography variant="h6" className={classes.title}>
            Rechtliches
          </Typography>
          <div>
            <Typography
              variant="body1"
              className={classes.element}
              component={Link}
              href="/datenschutz"
            >
              Datenschutz
            </Typography>
          </div>
          <div>
            <Typography
              variant="body1"
              className={classes.element}
              button
              component={Link}
              href="/agb"
            >
              AGB s
            </Typography>
          </div>
          <div>
            <Typography
              variant="body1"
              button
              component={Link}
              href="/widerrufsbelehrung"
              className={classes.element}
            >
              Widerrufsbelehrung
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
